@import "common";

.chart_dialog {
    background: #333;
    color: white;
    overflow: unset;

    .header {
        background: #333 !important;
        color: white;

        .title {
            color: white !important;
        }
    }

    .chart-tabbar {
        pointer-events: auto;
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 30px;

        .tab {
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            height: 100%;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            padding: 0px 20px;
            cursor: pointer;

            &.selected {
                background: #f7f8f9;
                color: $selectionColor;
            }
        }
    }

    padding: 0px;
    $column_width: 75px;
    $row_height: 34px;
    $series_width: 235px;

    $waterfall_column_width: 130px;
    $waterfall_series_width: 0px;

    .control.button {
        color: #333 !important;
        background: transparent !important;
        padding: 0px !important;
        border: none !important;
        margin-bottom: 0px !important;
        &:hover {
            background: none !important;
            color: $selectionColor !important;
        }
    }

    .contents {
        color: #333;
        display: flex;
        padding: 15px 30px !important;
    }

    .tab-contents {
        display: flex;
        width: 100%;
    }

    .control-group {
        margin-right: 30px;
        border-right: solid 1px rgba(0, 0, 0, 0.1);
        padding-right: 30px;

        &.disabled {
            pointer-events: none;
            opacity: 0.5;
            div {
                pointer-events: none;
            }
        }

        .control {
            display: flex !important;

            label {
                min-width: 100px !important;
                margin-right: 0px;
            }
        }

        .control.textinput {
            input {
                width: 120px;
            }
        }

        .control.slider {
            margin-bottom: 0px;
            height: 36px;
            input {
                min-width: 150px;
                width: 150px !important;
                padding: 6px 0px;
            }
        }

        .control.multi_toggle.singleline {
            margin-top: 5px;
            label {
                margin-left: 0px;
            }
        }
    }

    .control.menu_button {
        position: relative;
        top: 1px;
    }
    .control.icon_menu {
        width: 20px;
        margin-right: 15px !important;
        img {
            width: 100%;
            transform: scale(1.6) translateY(1px);
        }
    }

    .contents > .control.button {
        margin-left: 27px;
    }

    .add_column_button {
        margin-left: 14px;
        background: $selectionColor;
        color: white;
        border-radius: 100px;
        width: 22px;
        height: 22px;
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 5px;

        &:before {
            font-family: "Material Icons";
            content: "\E145";
            font-size: 18px;
            //position: absolute;
            //top: 4px;
            //left: 4px;
        }
    }

    .table-frame {
        width: calc(100% + 20px);
        margin-left: -20px;
    }

    .table_box {
        position: relative;

        input {
            background: lighten($selectionColor, 40%);
            outline-color: $selectionColor;
        }

        .column_options {
            position: absolute;
            color: $selectionColor;
            font-size: 20px;
            background: white;
            height: 22px;

            i {
                color: $selectionColor;
            }

            cursor: default;
            z-index: 100;
        }

        .data_options_button {
            position: absolute;
            color: white;
            background: $selectionColor;
            border-radius: 100px;
            padding: 0px;
            width: 20px;
            height: 20px;
            cursor: default;
            @include font-icon("\E5C5");

            &:before {
                font-size: 20px;
                text-align: center;
                margin-right: 0px;
            }
        }

        .column_rollovers {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0px;
            left: $series_width + 1;

            .column_rollover {
                width: $column_width + 3;
                height: 100%;
                display: inline-block;
                background: red;
                opacity: 0.2;
            }
        }
    }

    table {
        display: inline-block;
        vertical-align: top;
        border-collapse: collapse;
        margin-bottom: 10px;

        width: auto;

        td {
            letter-spacing: 0;
            min-width: $column_width;
            height: $row_height;
            border: solid 1px #bbb;
            background: white;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:focus {
                background: lighten($selectionColor, 35%);
                outline-color: $selectionColor;
                outline-offset: -2px;
            }
        }

        td.blank {
            border: none;
            background: none;
            opacity: 0;
        }

        td.delete_row {
            width: 30px;
            background: none;
            border: none;
            @include font-icon("\E5CD");
            opacity: 0;
        }

        td.locked {
            outline: none;
        }

        td.label {
            border: none;
            background: none;
            font-weight: bold;
            //width: 80px;
            padding-right: 10px;
            text-align: right;
        }

        //td.add_column{
        //  font-style: italic;
        //  font-weight: 300;
        //  color: #777;
        //}
    }

    .colWidgets {
        margin-bottom: 2px;
        display: flex;
    }

    .colWidget {
        display: inline-block;
        width: $column_width + 3;
        //&:first-of-type {
        //  width: $column_width + 4;
        //}
        height: 22px;
        background: lighten($selectionColor, 30%);
        border: solid 1px lighten($selectionColor, 20%);
        color: $darkSelectionColor;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        border-right: none;

        &:last-of-type {
            border-right: solid 1px lighten($selectionColor, 20%);
        }

        vertical-align: top;

        &:not(.last):hover {
            //background: $selectionColor;
            //color: white;

            .control {
                label {
                    color: white;
                }

                i {
                    color: white;
                }
            }
        }

        .control {
            width: 100%;
            padding: 2px 0px 0px 10px !important;
            height: 100%;
            background: none !important;
            border: none;
            min-width: 0px;

            label {
                color: $selectionColor;
            }

            i {
                color: $selectionColor;
            }
        }

        &.last {
            background: none;
            border: none;
            color: $darkSelectionColor;
            display: inline-flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            font-style: italic;
            font-size: 10px;
        }

        &.selected {
            background: $selectionColor;
            color: white;
        }
    }

    .standard-chart {
        width: 100%;
        .import-data-button {
            position: absolute !important;
            margin-left: 25px;
            margin-top: 20px;
        }
        .pie-import-data-button {
            margin-right: 30px;
        }

        & .buttons-box {
            & .datasource {
                margin: 0 20px 0;
                & .micon:first-child {
                    color: $newBlue;
                }
                &.linked .micon:first-child {
                    color: $warning;
                }
            }
        }
    }

    .add-series-button {
        margin-left: 20px;
        margin-bottom: 50px;
    }

    table.series {
        display: inline-block;
        width: $series_width;
        margin-left: 17px;
        margin-right: 1px;

        tr.blank {
            border: none;
            background: none;
            height: $row_height + 27;
            display: flex;
            align-items: center;

            & .datasource {
                margin: 20px 0 0 10px;
                & .micon:first-child {
                    color: $newBlue;
                }
                &.linked .micon:first-child {
                    color: $warning;
                }
            }
        }

        td.series_label {
            width: $series_width - 1;
            text-align: left;
            position: relative;
            overflow: visible;
            border: solid 1px #bbb;

            &.datasource_linked {
                pointer-events: none;
            }

            input {
                text-align: left;
                display: inline-block;
                font-weight: 600;
                width: 140px;
                vertical-align: middle;
                margin: 0px;
                border: none;
                box-sizing: border-box;
                outline: none;
                min-width: 80px;
            }

            .control {
                margin: 0px 10px 0px 0px;
                display: inline-block;
                vertical-align: middle;
                height: 20px;

                &.color_picker {
                    padding: 0px;
                }

                .popup_arrow {
                    vertical-align: 0px;
                }
            }

            .series_drag_handle {
                position: absolute;
                top: -1px;
                left: -18px;
                width: 18px;
                height: 38px;
                display: inline-block;
                background: url("../images/ui/drag_handle.png") no-repeat 4px
                    11px;
                background-color: $selectionColor;
                opacity: 0;
                cursor: ns-resize;
            }

            &:hover {
                .series_drag_handle {
                    opacity: 1;
                }
            }

            &.new_series {
                padding-left: 10px;
                font-weight: 300;
                color: #777;
                font-style: italic;
                cursor: default;
            }
        }

        td.chart_type {
            border: none;

            input {
                width: 100px;
            }
        }
    }

    table.datatable {
        max-width: calc(100% - 270px);
        overflow-x: auto;

        &.datasource_linked {
            pointer-events: none;
        }

        tr.column_options {
            td {
                height: 14px;
                background: none;
                border: none;
            }
        }

        tr.categories {
            td {
                font-weight: 600;
                text-align: center;
                min-width: $column_width;
                max-width: $column_width;
                border: solid 1px #bbb;
                text-overflow: ellipsis;
            }
        }

        tr.series {
            td.data_value {
                min-width: $column_width;
                max-width: $column_width;
                text-align: center;
            }
        }

        tr:hover {
            td.delete {
                opacity: 1;
            }
        }

        td.delete {
            opacity: 0;
            border: none;
            text-align: left;
            padding-left: 7px;
            width: 50px;

            i {
                font-size: 14px;
                vertical-align: middle;
            }
        }

        td.label {
            width: 50px !important;
            padding-right: 20px;
        }

        &.pie {
            max-width: calc(100% - 30px);

            .colWidgets {
                margin-left: 71px;

                .colWidget {
                    width: 153px;
                }
            }

            td {
                &.label {
                    min-width: 50px;
                }

                max-width: 150px;
                width: 150px;
                min-width: 150px;
            }
        }
    }

    .axis_tab {
        .axis_picker {
            float: left;
            margin-right: 30px;

            .axis_tab_button {
                font-size: 24px;
                font-weight: 600;
                color: #333;
                margin-bottom: 10px;
                cursor: default;

                &.selected {
                    color: $selectionColor;
                }
            }
        }
    }

    .waterfall-chart {
        width: 100%;
        .row {
            margin-bottom: 10px;
            & > div {
                margin-right: 20px;
                align-items: center;
            }
            & .datasource {
                margin: 15px 20px 0 10px;
                & .micon:first-child {
                    color: $newBlue;
                }
                &.linked .micon:first-child {
                    color: $warning;
                }
            }
        }
        .table_box {
            .column_rollovers {
                left: $waterfall_series_width + 1;

                .column_rollover {
                    width: $waterfall_column_width + 3;
                }
            }
        }
        table {
            td {
                min-width: $waterfall_column_width;
            }
        }
        .colWidget {
            width: $waterfall_column_width + 3;
        }
        table.series {
            width: $waterfall_series_width;
            td.series_label {
                width: $waterfall_series_width - 1;
            }
            &.datasource_linked {
                pointer-events: none;
            }
        }

        table.datatable {
            max-width: calc(100% - 50px);
            tr.categories {
                td {
                    min-width: $waterfall_column_width;
                    max-width: $waterfall_column_width;
                }
            }
            tr.series {
                td.data_value {
                    min-width: $waterfall_column_width;
                    max-width: $waterfall_column_width;
                }
            }
        }
    }
}
